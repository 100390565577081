<template>
  <v-container
    class="background"
    fill-height
    fluid
  >
    <v-row class="left-right-container">
      <v-col class="left-area ma-1">
        <map-component
          mode="simple"
        />
      </v-col>
      <v-col class="right-area ma-1">
        <v-col
          class="btn-area"
        >
          <template>
            <v-col cols="12">
              <h1 class="title ma-3">
                화면 옵션
              </h1>
            </v-col>
            <v-switch
              class="d-inline-block"
              v-model="grid"
              dark
              label="그리드"
              color="primary"
              inset
              @change="saveData('grid')"
            ></v-switch>
            <br/>
            <v-switch
              class="d-inline-block"
              v-model="lidar"
              dark label="라이다"
              color="primary"
              inset
              @change="saveData('lidar')"
            ></v-switch>
            <br/>
            <v-switch
              class="d-inline-block"
              v-model="radius"
              dark label="반경"
              color="primary"
              inset
              @change="saveData('radius')"
            ></v-switch>
            <br/>
            <v-switch
              class="d-inline-block"
              v-model="scale"
              dark label="스케일바"
              color="primary"
              inset
              @change="saveData('scale')"
            ></v-switch>
            <br/>

            <v-col cols="12">
              <h1 class="title ma-3">
                이동 트리거
              </h1>
            </v-col>
            <v-slider
              track-color="primary"
              :step="approach_step"
              v-model="approach"
              dark
              :max="approach_max"
              :min="approach_min"
              :label="`${approach.toFixed(1)}m 안으로 접근시`"
              @end="()=> saveData('approach')"
            >
            </v-slider>
            <v-btn dark @click="upApproach">
              +
            </v-btn>
            <v-btn dark @click="downApproach">
              -
            </v-btn>
            <br/>

            <v-slider
              track-color="primary"
              :step="speed_step"
              v-model="speed"
              dark
              :max="speed_max"
              :min="speed_min"
              :label="`${speed.toFixed(1)}m/s 속도로 제한`"
              @end="()=> saveData('speed')"
            >
            </v-slider>
            <v-btn dark @click="upSpeed">
              +
            </v-btn>
            <v-btn dark @click="downSpeed">
              -
            </v-btn>
            <br/>
          </template>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from '@/components/map/map.vue';
import EventBus from "@/main";

export default {
  name: 'Simple',
  components: {
    MapComponent,
  },
  data() {
    return {
      grid: false,
      lidar: false,
      radius: false,
      scale: false,
      approach_step: 0.1,
      approach: 3.0,
      approach_min: 0.0,
      approach_max: 25.0,
      speed_step: 0.1,
      speed: 0.0,
      speed_min: 0.0,
      speed_max: 1.0,
    };
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
  },
  methods: {
    async loadData(){
      const res = await this.$axios.get('/info');
      if (res.status !== 200) return;

      const options= res.data;
      for(const option_name in options){
        switch (option_name){
          case'move_trigger_radius': this.approach= options[option_name]; break;
          case'move_trigger_speed': this.speed= options[option_name]; break;
          case'map_option_grid': this.grid= options[option_name]; break;
          case'map_option_lidar': this.lidar= options[option_name]; break;
          case'map_option_radius': this.radius= options[option_name]; break;
          case'map_option_scale': this.scale= options[option_name]; break;
        }
      }
    },
    async saveData(name){
      let value= '';
      let option= '';
      switch (name){
        case'approach':
          this.$store.state.move_trigger_radius= this.approach;
          option= 'move_trigger_radius';
          value= this.approach;
          break;
        case'speed':
          this.$store.state.move_trigger_speed= this.speed;
          option= 'move_trigger_speed';
          value= this.speed;
          break;
        case'grid':
          EventBus.$emit('useGrid', this.grid);
          this.$store.state.map_option_grid= this.grid;
          option= 'map_option_grid';
          value= this.grid;
          break;
        case'lidar':
          EventBus.$emit('useLidar', this.lidar);
          this.$store.state.map_option_lidar= this.lidar;
          option= 'map_option_lidar';
          value= this.lidar;
          break;
        case'radius':
          EventBus.$emit('useRadius', this.radius);
          this.$store.state.map_option_radius= this.radius;
          option= 'map_option_radius';
          value= this.radius;
          break;
        case'scale':
          EventBus.$emit('useScale', this.scale);
          this.$store.state.map_option_scale= this.scale;
          option= 'map_option_scale';
          value= this.scale;
          break;
      }
      const res = await this.$axios.put('/robot/option', {
        option_name: option,
        option_value: value,
      });
      if (res.status === 200) await this.loadData();
    },
    upSpeed(){
      if(this.speed + this.speed_step > this.speed_max) return;
      this.speed += this.speed_step;
      this.saveData('speed');
    },
    downSpeed(){
      if(this.speed - this.speed_step < this.speed_min) return;
      this.speed -= this.speed_step;
      this.saveData('speed');
    },
    upApproach(){
      if(this.approach + this.approach_step > this.approach_max) return;
      this.approach += this.approach_step;
      this.saveData('approach');
    },
    downApproach(){
      if(this.approach - this.approach_step < this.approach_min) return;
      this.approach -= this.approach_step;
      this.saveData('approach');
    },
  },
}
</script>

<style scoped>
.title {
  color: #ffffff;
  font-size: 50px;
}

.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 95vh;
}

.background > .left-right-container > .right-area > .btn-area {
  height: 85vh;
  overflow: auto;
}

.background > .left-right-container > .left-area {
  padding: 20px;
}

label {
  color: white;
}

.v-slider__thumb {
  height: 20px;
  width: 20px;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px;
}

@media (max-width: 1700px) {

}

@media (max-width: 1024px) {
  .title {
    font-size: 16px;
  }
}

</style>
